import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  HeaderSearchBarFormValues,
  headerSearchBarSchema,
} from "./header-search-bar-schema";
import { Button, IconButton } from "@aprosoja/ui";

type HeaderSearchBarProps = {
  alwaysOpen?: boolean;
};

const HeaderSearchBar = ({ alwaysOpen }: HeaderSearchBarProps) => {
  const { push } = useRouter();
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSearchOpen, setIsSearchOpen] = useState(alwaysOpen);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<HeaderSearchBarFormValues>({
    resolver: zodResolver(headerSearchBarSchema),
    defaultValues: {
      search: "",
    },
  });

  const onSubmitSearch = ({ search }: HeaderSearchBarFormValues) => {
    push(`/resultados?search=${search}`);
    reset();
  };

  const toggleSearch = () => {
    if (!alwaysOpen) {
      setIsSearchOpen(!isSearchOpen);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target as Node) &&
      !alwaysOpen
    ) {
      setIsSearchOpen(false);
    }
  };

  useEffect(() => {
    if (!alwaysOpen) {
      if (isSearchOpen) {
        document.addEventListener("mousedown", handleClickOutside);
        inputRef.current?.focus();
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isSearchOpen]);

  return (
    <div
      ref={searchRef}
      className={`border border-th-color-neutral-900 flex rounded-border-radius-small 
        ${
          isSearchOpen
            ? "w-full tabletMedium:w-[198px] tabletLarge:w-[298px] desktopMedium:w-[360px] ring-2 ring-th-primary-color-lightest"
            : "max-w-[50px]"
        }`}
    >
      <Button
        variant="text"
        startIcon="search"
        size="large"
        onClick={toggleSearch}
        className={`bg-th-color-neutral-0 ${
          isSearchOpen
            ? "rounded-l-border-radius-small focus:ring-0"
            : "rounded-border-radius-small"
        }`}
      />
      <form
        onSubmit={handleSubmit(onSubmitSearch)}
        className={`h-[48px] w-full flex items-center transition-all duration-300 ease-in-out ${
          isSearchOpen
            ? "w-full tabletMedium:w-[150px] tabletLarge:w-[250px] desktopMedium:w-[312px] opacity-100"
            : "w-0 opacity-0"
        }  bg-th-color-neutral-0  rounded-r-border-radius-small`}
      >
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              placeholder="Busque por notícia ou categorias"
              autoFocus={isSearchOpen}
              className=" text-th-color-neutral-900 w-full bg-th-color-neutral-0 ring-0 outline-none"
            />
          )}
        />

        <div className="flex items-center justify-center pr-1">
          {isValid && (
            <IconButton
              onClick={() => reset({ search: "" })}
              variant="ghost"
              name="cancel"
              color="th-color-neutral-200"
              className={
                isSearchOpen
                  ? "cursor-pointer w-[48px] flex items-center justify-center hover:bg-th-color-neutral-0"
                  : "hidden"
              }
            />
          )}
          <IconButton
            name="arrow_forward"
            variant="ghost"
            className={
              isSearchOpen
                ? "cursor-pointer w-[48px] flex items-center justify-center hover:bg-th-color-neutral-0"
                : "hidden"
            }
          />
        </div>
      </form>
    </div>
  );
};

export default HeaderSearchBar;
