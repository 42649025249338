"use client";

import Link from "next/link";
import Image from "next/image";
import { Menu, X } from "lucide-react";
import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";

import { Button } from "@aprosoja/ui";
import { useMediaQuery } from "@aprosoja/hooks";

import logo from "../../../public/logo.png";
import Navigation from "./components/navigation/navigation";
import HeaderSearchBar from "./components/header-search-bar/header-search-bar";

const MEMBERS_URL =
  process.env.MEMBERS_URL ?? process.env.NEXT_PUBLIC_MEMBERS_URL;

const SiteHeader = () => {
  const isTabletMedium = useMediaQuery("(min-width: 834px)");
  const { push } = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isTabletMedium) {
      setIsMenuOpen(false);
    }
  }, [isTabletMedium]);

  return (
    <div className="w-full max-w-[1920px]">
      <div className="hidden w-full max-w-[1920px] flex-col px-10 gap-4 tabletMedium:flex">
        <div className="w-full grid grid-cols-3 items-center justify-between py-4 gap-size-x2">
          <HeaderSearchBar />
          <div className="flex flex-col gap-8 justify-self-center">
            <Link href={"/"}>
              <Image
                priority
                src={logo}
                alt="Logo Aprosoja"
                width={271}
                height={80}
                placeholder="blur"
                blurDataURL={logo.blurDataURL}
              />
            </Link>
          </div>
          <Link
            href={MEMBERS_URL ?? ""}
            target="_blank"
            className="justify-self-end"
          >
            <Button
              variant="outlined"
              endIcon="login"
              className="border-th-color-neutral-900 text-th-color-neutral-900 rounded-border-radius-small"
              size="large"
            >
              Entrar
            </Button>
          </Link>
        </div>
        <Navigation />
      </div>
      <div className="relative tabletMedium:hidden">
        <div>
          <Navigation
            className={`absolute top-[74px] left-0 right-0 z-40 transform transition-transform duration-300 ease-in-out ${
              isMenuOpen ? "translate-y-0" : "-translate-y-full"
            }`}
          />
        </div>
        <div className="z-50 w-full flex items-center justify-between p-4 relative bg-white">
          <Button
            onClick={toggleMenu}
            className="!p-2 !h-fit rounded-lg"
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </Button>

          <Link href={"/"}>
            <Image
              src={logo}
              alt="Logo Aprosoja"
              width={136}
              height={40}
              priority
              placeholder="blur"
              blurDataURL={logo.blurDataURL}
            />
          </Link>
          <Button
            variant="outlined"
            endIcon="login"
            className="justify-self-end border-th-color-neutral-900 text-th-color-neutral-900 rounded-border-radius-small !h-fit !p-2"
            size="large"
            onClick={() => push("/area-associado")}
          />
        </div>
      </div>
    </div>
  );
};

export default SiteHeader;
