import { StaticImageData } from "next/image";
import ap from "./ap.png";
import ba from "./ba.png";
import go from "./go.png";
import ma from "./ma.png";
import mt from "./mt.png";
import ms from "./ms.png";
import mg from "./mg.png";
import pa from "./pa.png";
import pi from "./pi.png";
import pr from "./pr.png";
import ro from "./ro.png";
import sc from "./sc.png";
import to from "./to.png";
import sp from "./sp.png";
import rs from "./rs.png";
import rr from "./rr.png";

export { ap, ba, go, ma, mt, ms, mg, pa, pi, pr, ro, sc, to, sp, rs, rr };

type Flags = {
  [key: string]: {
    name: string;
    image: StaticImageData;
  };
};

export const statesFlags: Flags = {
  ap: {
    name: "Amapá",
    image: ap,
  },
  ba: {
    name: "Bahia",
    image: ba,
  },
  go: {
    name: "Goiás",
    image: go,
  },
  ma: {
    name: "Maranhão",
    image: ma,
  },
  mt: {
    name: "Mato Grosso",
    image: mt,
  },
  ms: {
    name: "Mato Grosso do Sul",
    image: ms,
  },
  mg: {
    name: "Minas Gerais",
    image: mg,
  },
  pa: {
    name: "Pará",
    image: pa,
  },
  pi: {
    name: "Piauí",
    image: pi,
  },
  pr: {
    name: "Paraná",
    image: pr,
  },
  ro: {
    name: "Rondônia",
    image: ro,
  },
  sc: {
    name: "Santa Catarina",
    image: sc,
  },
  to: {
    name: "Tocantins",
    image: to,
  },
  sp: {
    name: "São Paulo",
    image: sp,
  },
  rs: {
    name: "Rio Grande do Sul",
    image: rs,
  },
  rr: {
    name: "Roraima",
    image: rr,
  },
};
