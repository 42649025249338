/* eslint-disable max-len */
import React from "react";
import Image from "next/image";

import { Text } from "@aprosoja/ui";

import * as s from "./styles";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "./ui/navigation-menu";
import { siteNavigation } from "../../../../config/routes";
import { statesFlags } from "../../../../../public/states/states-flag";
import { cn } from "@aprosoja/utils";
import HeaderSearchBar from "../header-search-bar/header-search-bar";

type NavigationProps = {
  className?: string;
};

const Navigation = ({ className }: NavigationProps) => {
  return (
    <NavigationMenu className={"w-full"}>
      <NavigationMenuList className={cn(s.navList(), className)}>
        <li className="w-full block tabletMedium:hidden">
          <HeaderSearchBar alwaysOpen />
        </li>
        {siteNavigation.map((siteItem) =>
          !siteItem.subItems ? (
            <NavigationMenuItem
              key={siteItem.label}
              className={
                (siteItem.path === "/associe-se" &&
                  "tabletMedium:w-[120px] !m-0 w-full flex p-size-base") ||
                "p-size-base w-full hover:bg-th-primary-color-medium/15 tabletMedium:p-0 focus-within:bg-th-primary-color-medium/15 rounded-border-radius-small"
              }
            >
              <NavigationMenuLink
                className={
                  (siteItem.path === "/associe-se" &&
                    "tabletMedium:w-[120px] w-full h-[40px] flex items-center justify-center tabletMedium:px-size-x3 bg-th-color-neutral-0 rounded-border-radius-small text-th-primary-color-main") ||
                  "w-full tabletMedium:max-w-[312px] h-[48px] flex items-center tabletMedium:justify-center text-th-color-neutral-0"
                }
                href={siteItem.path}
                target={siteItem.targetBlank ? "_blank" : "_self"}
              >
                {siteItem.label}
              </NavigationMenuLink>
            </NavigationMenuItem>
          ) : (
            <NavigationMenuItem
              key={siteItem.label}
              className={`w-full h-[48px] flex items-start p-size-base tabletMedium:items-center tabletMedium:justify-center rounded-border-radius-small hover:bg-th-primary-color-medium/15 focus-within:bg-th-primary-color-medium/15 ${!!siteItem.icon && "tabletMedium:max-w-[120px]"}`}
            >
              <NavigationMenuTrigger className={s.trigger()} color="#F3F4F6">
                <Text
                  color="th-color-neutral-0"
                  type="labelM"
                  className="flex items-center justify-center gap-size-base"
                >
                  {siteItem.icon && (
                    <Image
                      src={statesFlags[siteItem.icon].image}
                      width={32}
                      height={22}
                      alt={`icone ${statesFlags[siteItem.icon].name}`}
                    />
                  )}
                  <span className="hidden tabletMedium:block">
                    {siteItem.label}
                  </span>
                  <span className="block tabletMedium:hidden">
                    {siteItem.mobileLabel}
                  </span>
                </Text>
              </NavigationMenuTrigger>
              <NavigationMenuContent className="w-full p-size-x2 bg-th-primary-color-main rounded-border-radius-small z-50">
                <ul className="flex flex-col items-center justify-center">
                  {siteItem.subItems.map((subItem) => (
                    <li key={subItem.label} className="w-full">
                      <NavigationMenuLink
                        href={subItem.path}
                        target={subItem.icon ? "_blank" : undefined}
                        className="w-full text-center text-th-color-neutral-0 py-size-x3 flex gap-size-base items-center justify-center rounded-border-radius-small hover:bg-th-primary-color-medium/15 focus-within:bg-th-primary-color-medium/15"
                      >
                        {subItem.icon && (
                          <Image
                            src={statesFlags[subItem.icon].image}
                            width={18}
                            height={18}
                            alt={`icone ${statesFlags[subItem.icon].name}`}
                          />
                        )}
                        <span className="hidden tabletMedium:block">
                          {subItem.label}
                        </span>
                        <span className="block tabletMedium:hidden">
                          {subItem.mobileLabel}
                        </span>
                      </NavigationMenuLink>
                    </li>
                  ))}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          )
        )}
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default Navigation;
