import { IRoute } from "@aprosoja/types";

export const siteRoutes = [
  "/",
  "/categoria",
  "/sobre-nos",
  "/historia",
  "/portal-de-privacidade",
  "/associadas-estaduais",
];

export const siteNavigation: IRoute[] = [
  {
    icon: "rs",
    label: "RS",
    mobileLabel: "Rio Grande do Sul",
    path: "",
    subItems: [
      {
        icon: "ap",
        label: "AP",
        mobileLabel: "Amapá",
        path: "https://aprosojaap.com.br/",
      },
      {
        icon: "ba",
        label: "BA",
        mobileLabel: "Bahia",
        path: "https://aprosojaba.com.br/",
      },
      {
        icon: "go",
        label: "GO",
        mobileLabel: "Goiás",
        path: "https://aprosojago.com.br/",
      },
      {
        icon: "ma",
        label: "MA",
        mobileLabel: "Maranhão",
        path: "https://aprosojama.com.br/",
      },
      {
        icon: "mt",
        label: "MT",
        mobileLabel: "Mato Grosso",
        path: "https://www.aprosoja.com.br/",
      },
      {
        icon: "ms",
        label: "MS",
        mobileLabel: "Mato Grosso do Sul",
        path: "https://aprosojams.org.br/",
      },
      {
        icon: "mg",
        label: "MG",
        mobileLabel: "Minas Gerais",
        path: "https://aprosojamg.com.br/",
      },
      {
        icon: "pa",
        label: "PA",
        mobileLabel: "Pará",
        path: "https://aprosojapa.com.br/",
      },
      {
        icon: "pi",
        label: "PI",
        mobileLabel: "Piauí",
        path: "https://aprosojapi.com.br/",
      },
      {
        icon: "pr",
        label: "PR",
        mobileLabel: "Paraná",
        path: "https://aprosojapr.com.br/",
      },
      {
        icon: "ro",
        label: "RO",
        mobileLabel: "Rondônia",
        path: "https://aprosojaro.com.br/",
      },
      {
        icon: "sc",
        label: "SC",
        mobileLabel: "Santa Catarina",
        path: "https://aprosojasc.com.br/",
      },
      {
        icon: "to",
        label: "TO",
        mobileLabel: "Tocantins",
        path: "https://aprosojato.com.br/",
      },
      {
        icon: "sp",
        label: "SP",
        mobileLabel: "São Paulo",
        path: "https://aprosojasp.com.br/",
      },
      {
        icon: "rs",
        label: "RS",
        mobileLabel: "Rio Grande do Sul",
        path: "/",
      },
      {
        icon: "rr",
        label: "RR",
        mobileLabel: "Roraima",
        path: "https://aprosojarr.com.br/",
      },
    ],
  },
  {
    icon: "",
    label: "Notícias",
    mobileLabel: "Notícias",
    path: "/noticias",
  },
  {
    icon: "",
    label: "Mídia",
    mobileLabel: "Mídia",
    path: "/midia",
  },
  {
    icon: "",
    label: "A soja",
    mobileLabel: "A soja",
    path: "/soja",
  },
  {
    icon: "",
    label: "Estatísticas",
    mobileLabel: "Estatísticas",
    path: "/estatisticas",
  },
  {
    icon: "",
    label: "Projetos",
    mobileLabel: "Projetos",
    path: "https://aprosojabrasil.com.br/projetos/",
    targetBlank: true,
  },
  {
    icon: "",
    label: "Associar",
    mobileLabel: "Associar",
    path: "/associe-se",
  },
];
